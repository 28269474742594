import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from '@core/header/header.component';
import { ContextService, CrispChatService, FeaturesService, ScreenService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import * as ProfileAction from '@store/actions/profile.actions';
import { expandWindow } from 'lingo2-chat-app';
import { FeatureEnum, User, UserStatusEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss'],
})
export class ProfileLayoutComponent extends DestroyableComponent implements OnInit, AfterViewInit {
  public pageStyle = '';
  public isChatVisible = false;
  public isNotificationsVisible = false;
  public alternativeChatState = false;
  public unreadMessageCount: number;
  public isFooterVisible = true;
  public fileFromChat = null;

  @ViewChild('app_header', { read: ViewContainerRef }) private headerContainerRef: ViewContainerRef;
  private headerComponentRef: ComponentRef<HeaderComponent>;
  protected me: User;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public contextService: ContextService,
    public features: FeaturesService,
    private screenService: ScreenService,
    public crispChat: CrispChatService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public store: Store,
    private route: ActivatedRoute,
    public deviceService: DeviceDetectorService,
  ) {
    super();
  }

  ngOnInit() {
    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        this.isFooterVisible = width < 870;
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.isFooterVisible = false;
      }
    });

    this.route.url.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => {
        const data = this.route.snapshot.data;
        this.pageStyle = data.page_style || '';
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      }
    });

    if (this.deviceService.isDesktop()) {
      this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (me) => {
          this.me = me;
          this.isChatVisible = me && me.status !== UserStatusEnum.guest;
          this.isNotificationsVisible = me && me.status !== UserStatusEnum.guest;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        }
      });
    }
    this.store.dispatch(ProfileAction.loadMe());
  }

  public ngAfterViewInit() {
    void this.loadEmptyComponent().catch();
  }

  // TODO ленивая загрузка компонента
  private async loadEmptyComponent(): Promise<void> {
    if (!this.headerContainerRef) {
      return;
    }
    const { HeaderComponent } = await import('../../../core/header/header.component');
    this.headerContainerRef?.clear();
    this.headerComponentRef = this.headerContainerRef?.createComponent(HeaderComponent);
    this.headerComponentRef.changeDetectorRef.detectChanges();
  }

  public get isAvailableChat(): boolean {
    return this.features.isAvailable(FeatureEnum.chat) && this.isBrowser;
  }

  public onChatWindowStatusChanged(status: boolean) {
    this.alternativeChatState = status;
    this.screenService.setBodyFixed(status);
  }

  public openChatWindow() {
    this.store.dispatch(expandWindow({ expand: true }));
  }

  public openProfile(e) {
    this.router.navigateByUrl('/u/' + e.slug).then(() => true);
    if (!this.deviceService.isDesktop()) {
      this.setTimeout(() => {
        this.onChatWindowStatusChanged(false);
        this.store.dispatch(expandWindow({ expand: false }));
      }, 200);
    }
  }

  public onMouseenter() {
    this.screenService.setBodyFixed(true);
  }

  public onMouseleave() {
    this.screenService.setBodyFixed(false);
  }

  public set unreadCount(count) {
    this.unreadMessageCount = count;
    this.cdr.detectChanges();
  }

  public get unreadCount() {
    return this.unreadMessageCount;
  }

  public openFileFromChat(event) {
    this.store.dispatch(expandWindow({ expand: false }));
    this.fileFromChat = event;
  }

  public closeFileFromChat() {
    this.store.dispatch(expandWindow({ expand: true }));
    this.fileFromChat = null;
  }
}
