import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IconModule } from '@app/shared';
import { ButtonFabModule } from '@core/components/button-fab/button-fab.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { MainLayoutModule } from '@core/layouts/main/main-layout.module';
import { SidebarService } from '@core/layouts/main/sidebar/sidebar.service';
import { VerificationWidgetModule } from '@core/widgets';
import { TranslateModule } from '@ngx-translate/core';
import { ChatAppModule } from 'lingo2-chat-app';
import { OnUiSpriteModule } from 'onclass-ui';
import { ProfileLayoutComponent } from './profile-layout.component';
import { ProfileLeftSidebarComponent } from './sidebar/profile-left-sidebar.component';
import { ProfileRightSidebarComponent } from './sidebar/profile-right-sidebar.component';

@NgModule({
  declarations: [ProfileLayoutComponent, ProfileLeftSidebarComponent, ProfileRightSidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    IconModule,
    ChatAppModule,
    ButtonFabModule,
    OnUiSpriteModule,
    MainLayoutModule,
    VerificationWidgetModule,
    MobileModalModule,
    ModalModule,
  ],
  exports: [ProfileLayoutComponent],
  providers: [SidebarService],
})
export class ProfileLayoutModule {}
