<div class="app app-main constructor-layout">
  <app-header></app-header>
  <main class="app-body">
    <div class="spacer"></div>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <div class="spacer right"></div>
  </main>

  <ng-template [ngIf]="isAvailableChat && isChatVisible">
    <div *ngIf="deviceService.isDesktop()" class="chat-action">
      <app-button-fab
        [icon]="'chat-bubble'"
        [counter]="unreadCount"
        [isToggled]="isChatExpanded"
        (click)="toggleChatWindow()">
      </app-button-fab>
    </div>
    <div class="chat-wrap"
         [class.display]="isChatVisible && alternativeChatState"
         (mouseenter)="onMouseenter()"
         (mouseleave)="onMouseleave()">
      <app-alternative-chat-app (chatIsOpened)="onChatWindowStatusChanged($event)"
                                (openProfile)="openProfile($event)" (unreadCount)="unreadCount = $event"
                                (openFile)="openFileFromChat($event)"
                                (support)="crispChat.openChat()">
      </app-alternative-chat-app>
    </div>
  </ng-template>

  <ng-container *ngIf="fileFromChat">
    <app-modal *ngIf="!deviceService.isMobile()" [thin]="true" [canClose]="true" (closed)="closeFileFromChat()">
      <app-modal-body>
        <img [src]="fileFromChat" class="chat-image" />
      </app-modal-body>
    </app-modal>

    <app-mobile-modal *ngIf="deviceService.isMobile()" (closed)="closeFileFromChat()">
      <app-mobile-modal-body>
        <img [src]="fileFromChat" class="chat-image" />
      </app-mobile-modal-body>
    </app-mobile-modal>
  </ng-container>
  <ng-container *ngIf="isAvailableNotifications">
    <app-notifications *ngIf="isNotificationsVisible" [isChatOpen]="alternativeChatState"></app-notifications>
  </ng-container>
</div>
